<template>
  <div class="forgetpwd">
    <!-- 头 -->
    <div class="header">
      <div class="imgs">
        <img src="../assets/logo1.png" alt />
      </div>
      <div class="title">
        <span>健康数据管理中心</span>
      </div>
    </div>
    <div class="main">
        <div class="forget">忘记密码</div>
      <el-form class="pwdform" :model="form" label-position="top">
        <el-form-item label="请输入忘记密码的用户名" :label-width="formLabelWidth">
          <el-input v-model="form.name" placeholder="请输入用户名" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="输入您与该用户名绑定的手机号" :label-width="formLabelWidth">
          <el-input v-model="form.tel" placeholder="已绑定的手机号码" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="but">
        <el-button type="primary" @click="changepwd">继续</el-button>
        <el-button type="primary" @click="cancel">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: "",
        tel: ""
      },
      formLabelWidth: "120px",
    };
  },
  methods: {
    // 取消
    cancel() {
       this.$router.replace("/");
    },
    // 修改密码 继续
    changepwd(){
        this.$router.push({  
            path: '/changepassword',   
            name: 'changepassword',  
            params: {   
                key: 'key',   
                msgKey: this.form  
            }  
        })  
    },
  }
};
</script>
<style lang="less">
.forgetpwd {
  .header {
    margin-top: 100px;
    .imgs {
      text-align: center;
      img {
        width: 356px;
        height: 66px;
      }
    }
    .title {
      font-size: 40px;
      text-align: center;
      color: #91aca7;
      margin-top: 40px;
      letter-spacing: 8px;
      span {
        font-family: "ExtraLight";
      }
    }
  }
  .main {
    width: 500px;
    margin: 0 auto;
    .forget{
    font-size: 34px;
      text-align: center;
      color: #a8a8a8;
      margin-top: 40px;
    }
    .el-form {
      margin-top: 40px;
     .el-form-item__label{
         font-size: 18px;
         color: #a8a8a8;
     }
    }

    .but {
      text-align: right;
      margin-top: 60px;
      .el-button {
        height: 50px;
        width: 100px;
        background: #d78673;
        border: none;
        border-radius: 33px;
        font-size: 18px;
      }
    }
  }
}
</style>